import React from 'react'
import Layout from '../components/Layout'

export default function notFound() {
    return (
        <Layout>
            <div>
                <h2>404 Error</h2>
                <p>Sorry, you've ended up in an unknown spot </p>
            </div>
        </Layout>
    )
}
